import { Div, Group, Header, SimpleCell } from '@vkontakte/vkui'

const InfoPanel = ({ kits = [] }) => {
  return (
    <Group>
      <Group mode="plain">
        <Div>
          <Header>Юридическая информация</Header>

          <SimpleCell disabled>
            ИП Дементьев Алексей Михайлович
          </SimpleCell>

          <SimpleCell disabled>
            ОГРНИП: 322508100005932
          </SimpleCell>

          <SimpleCell disabled>
            Дата регистрации: 12 января 2022 г.
          </SimpleCell>

          <SimpleCell disabled>
            Место государственной регистрации: Межрайонная инспекция Федеральной налоговой службы №23 по Московской области
          </SimpleCell>

          <SimpleCell disabled>
            Контактные данные: playa.aus@gmail.com
          </SimpleCell>
        </Div>
      </Group>

      <Group mode="plain">
        <Div>
          <Header>Описание товаров</Header>

          {kits.map((kit) =>
            <SimpleCell disabled key={kit.id}>
              Набор - {kit.name}
              <ul>
                {kit.elements.map((element) =>
                  <li key={element.id}>{element.name}</li>
                )}
              </ul>
            </SimpleCell>
          )}
        </Div>
      </Group>
    </Group>
  )
}

export default InfoPanel
