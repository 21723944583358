import {
  useAdaptivity,
  AppRoot,
  SplitLayout,
  SplitCol,
  ViewWidth,
  View,
  Panel,
  PanelHeader,
  Button,
  Footer,
  Link,
  PanelHeaderBack, PanelHeaderButton, ConfigProvider, AdaptivityProvider, WebviewType, Avatar, Spinner
} from "@vkontakte/vkui";
import '@vkontakte/vkui/dist/fonts.css'
import '@vkontakte/vkui/dist/vkui.css'
import { Icon28Game, Icon56ErrorTriangleOutline } from "@vkontakte/icons";
import { useState, useEffect } from "react";
import axios from "./api";
import PaymentForm from './components/PaymentForm'
import InfoPanel from './components/InfoPanel'

const App = () => {
  const { viewWidth } = useAdaptivity();
  const [activePanel, setActivePanel] = useState('main');
  const [kits, setKits] = useState([]);
  const [kitOptions, setKitOptions] = useState([]);
  const [isKitLoading, setIsKitLoading] = useState(true);
  const [kitError, setKitError] = useState(false);

  useEffect (() => {
    axios
      .get('/kits')
      .then((response) => {
        setKits(response.data)
        setKitOptions(response.data.map((kit) => {
          return {
            label: kit.name,
            value: kit.id,
            price: kit.price
          }
        }))
      })
      .catch(() => {
        setKitError(true)
      })
      .finally(() => {
        setIsKitLoading(false)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const title = window.appConfig.botName
    ? window.appConfig.botName.toUpperCase()
    : 'Carlbot & MGN'

  return (
    <ConfigProvider
      webviewType={WebviewType.INTERNAL}
      scheme="inherit"
    >
      <AdaptivityProvider>
        <AppRoot>
          <SplitLayout
            header={<PanelHeader separator={false} />}
          >
            <SplitCol spaced={ viewWidth && viewWidth > ViewWidth.MOBILE }>
              <View activePanel={activePanel}>
                <Panel id="main" centered>
                  <PanelHeader
                    before={
                      <Avatar size={44} src="/images/avatar.jpg" />
                    }
                    after={
                      <PanelHeaderButton aria-label="Играть" href={'https://vk.com/im?sel=-' + window.appConfig.groupId} target="_blank" size="m">
                        <Icon28Game aria-hidden="true" />
                      </PanelHeaderButton>
                    }
                  >
                    <h1 style={{margin: 0, fontSize: '22px'}}>{title}</h1>
                  </PanelHeader>

                  {
                    isKitLoading
                      ? <Spinner size="large" />
                      : !kitError
                          ? <PaymentForm kits={kitOptions} />
                          : <Icon56ErrorTriangleOutline width={128} height={128} fill="#2688eb" />
                  }

                  <Footer>
                    <Link onClick={() => setActivePanel('information')}>Юридическая информация и описание товаров</Link>
                  </Footer>
                </Panel>

                <Panel id="information">
                  <PanelHeader
                    before={<PanelHeaderBack onClick={() => setActivePanel('main')}/>}
                  >
                    Информация
                  </PanelHeader>

                  <InfoPanel kits={kits} />

                  <Footer>
                    <Button href={'https://vk.com/im?sel=-' + window.appConfig.groupId} target="_blank" size="m">Перейти к игре</Button>
                  </Footer>
                </Panel>
              </View>
            </SplitCol>
          </SplitLayout>
        </AppRoot>
      </AdaptivityProvider>
    </ConfigProvider>
  );
};

export default App;
