export const isUrl = (url) => {
  if(typeof url !== 'string')
    return false;

  return (/^[-a-zA-Z0-9@:%_+.~#?&/=]{2,256}\.[a-z]{2,4}\b\/([a-zA-Z0-9_]+)$/).test(url.trim())
}

export const toClientId = (url) => {
  if (isUrl(url)) {
    return url.trim().match(/^[-a-zA-Z0-9@:%_+.~#?&/=]{2,256}\.[a-z]{2,4}\b\/([a-zA-Z0-9_]+)$/)[1] || null
  }
  return null
}
