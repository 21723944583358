import {Button, CustomSelect, CustomSelectOption, FormItem, Group, Input, Snackbar} from "@vkontakte/vkui";
import {isUrl, toClientId} from "../helpers/utils";
import {Icon16ErrorCircleFill, Icon20ThumbsUpCircleFillGreen, Icon20User} from "@vkontakte/icons";
import {methods} from "../helpers/payments";
import {useCallback, useMemo, useState} from "react";
import axios from "../api";

const PaymentForm = ({ kits = [] }) => {
  const [user, setUser] = useState('');
  const [donate, setDonate] = useState(kits[0]?.value || null);
  const [payment, setPayment] = useState('bank_card');
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState(null);

  const isValidForm = useMemo(() => {
    return kits.some((el) => el.value === donate) && methods.some((el) => el.value === payment) && isUrl(user)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donate, user, payment]);

  const onBuyDonate = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.post('/payment/create', {
        client: toClientId(user),
        kitId: donate,
        paymentMethod: payment,
      })

      console.log(response)

      if([200, 201].includes(response.status)) {
        window.open(response.data.url, '_blank');

        setSnackbar(
          <Snackbar
            before={<Icon20ThumbsUpCircleFillGreen aria-hidden="true"/>}
            onClose={() => setSnackbar(null)}
          >
            Платеж успешно создан, вас перенаправит на форму оплаты
          </Snackbar>
        )
      } else {
        setSnackbar(
          <Snackbar
            before={<Icon16ErrorCircleFill aria-hidden="true"/>}
            onClose={() => setSnackbar(null)}
          >
            {response.data.error || 'ошибка создания платежа'}
          </Snackbar>
        );
      }
    } catch (e) {
      setSnackbar(
        <Snackbar
          before={<Icon16ErrorCircleFill aria-hidden="true"/>}
          onClose={() => setSnackbar(null)}
        >
          {e.response.data.error || 'ошибка создания платежа'}
        </Snackbar>
      );
    } finally {
      setLoading(false);
    }
  }, [user, payment, donate]);

  return (
    <Group>
      {snackbar}

      <FormItem
        top="Ссылка на Ваш профиль VK"
        status={(user && !isUrl(user)) && 'error'}
        bottom={(user && !isUrl(user)) && 'Указана неверная ссылка на профиль'}
      >
        <Input
          value={user}
          onChange={(e) => setUser(e.target.value)}
          type="text"
          placeholder='Ссылка на профиль'
          before={<Icon20User aria-hidden="true" />}
        />
      </FormItem>

      <FormItem top="Донат набор">
        <CustomSelect
          placeholder="Не выбран"
          value={donate}
          onChange={(e) => setDonate(parseInt(e.target.value))}
          options={kits}
          renderOption={({ option, ...restProps }) => (
            <CustomSelectOption
              {...restProps}
              description={option.price + ' ₽'}
            >
              {option.label}
            </CustomSelectOption>
          )}
        />
      </FormItem>

      <FormItem top="Способ оплаты">
        <CustomSelect
          placeholder="Не выбран"
          value={payment}
          onChange={(e) => setPayment(e.target.value)}
          options={methods}
        />
      </FormItem>

      <FormItem>
        <Button disabled={loading || !isValidForm} onClick={onBuyDonate} size="l" sizeY="compact" stretched loading={loading}>Купить</Button>
      </FormItem>
    </Group>
  )
}

export default PaymentForm
